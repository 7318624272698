<template>
  <b-modal title="Export to Freshsales" id="freshsales-export-modal" hide-footer>
    <b-form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="fs-parent-account-id">
          Parent Account ID*
          <font-awesome-icon
            :icon="['far', 'question-circle']"
            class="text-muted mr-2"
            v-b-popover.hover="
              'Account IDs can be found at the end of its Freshsales URL. If an accountant has over 50 organisations, make sure to create multiple parent accounts and provide the correct ID.'
            "
          />
        </label>
        <b-form-input id="fs-parent-account-id" v-model="form.parentAccountId" required />
      </div>

      <h5 class="mb-3">Contact details</h5>
      <b-row>
        <b-form-group label="First Name*" label-for="fs-first-name" class="col-6">
          <b-form-input id="fs-first-name" v-model="contact.first_name" required />
        </b-form-group>

        <b-form-group label="Last Name" label-for="fs-last-name" class="col-6">
          <b-form-input id="fs-last-name" v-model="contact.last_name" />
        </b-form-group>
      </b-row>

      <b-form-group label="Email*" label-for="fs-email">
        <b-form-input id="fs-email" v-model="contact.email" type="email" required />
        <div class="suggestion-tags">
          <b-badge v-for="email in suggestions.emails" :key="email" @click="contact.email = email">
            {{ email }}
          </b-badge>
        </div>
      </b-form-group>

      <b-form-group label="Mobile Number" label-for="fs-mobile-number">
        <b-form-input id="fs-mobile-number" v-model="contact.mobile_number" />
        <div class="suggestion-tags">
          <b-badge
            v-for="number in suggestions.phoneNumbers"
            :key="number"
            @click="contact.mobile_number = number"
          >
            {{ number }}
          </b-badge>
        </div>
      </b-form-group>

      <b-form-group label="Accountant Name" label-for="fs-accountant-name">
        <b-form-input id="fs-accountant-name" v-model="contact.accountantName" />
        <div class="suggestion-tags">
          <b-badge
            v-for="name in suggestions.names"
            :key="name"
            @click="contact.accountantName = name"
          >
            {{ name }}
          </b-badge>
        </div>
      </b-form-group>

       <b-form-group label="Accountant Email" label-for="fs-accountant-email">
        <b-form-input id="fs-accountant-email" v-model="contact.accountantEmail" type="email" />
        <div class="suggestion-tags">
          <b-badge v-for="email in suggestions.emails" :key="email" @click="contact.accountantEmail = email">
            {{ email }}
          </b-badge>
        </div>
      </b-form-group>

      <!-- Server response message -->
      <div class="mt-2">
        <b-alert :show="successMessage != null" v-html="successMessage" variant="success"></b-alert>
        <b-alert :show="errorMessage != null" variant="danger">{{ errorMessage }}</b-alert>
      </div>

      <div class="d-flex justify-content-end">
        <div class="button-wrapper">
          <b-button type="submit" variant="primary" :disabled="busy" block>
            <div v-if="busy">
              <Busy light :margin="0" :size="1.3" />
            </div>
            <div v-else>Submit</div>
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import Console from "@/console";

export default {
  name: "FreshsalesExportModal",
  props: {
    organisation: Object
  },
  components: { Busy },
  data() {
    return {
      form: {
        parentAccountId: null,
        contacts: {},
        organisationIds: []
      },
      contact: {
        first_name: null,
        last_name: null,
        email: null,
        mobile_number: null,
        accountantName: null,
        accountantEmail: null
      },
      suggestions: {
        emails: new Set(),
        phoneNumbers: new Set(),
        names: new Set()
      },
      busy: false,
      successMessage: null,
      errorMessage: null
    };
  },
  watch: {
    organisation: function () {
      this.resetForm();

      if (this.organisation) {
        if (this.organisation.phoneNumbers) {
          this.organisation.phoneNumbers.forEach((number) => {
            if (number && number != "") {
              this.suggestions.phoneNumbers.add(number);
            }
          });
        }

        if (this.organisation.sourceUsers) {
          this.organisation.sourceUsers.forEach((user) => {
            if (!user.email.includes("@reducer.co.uk")) {
              if (user.email) {
                this.suggestions.emails.add(user.email);
              }
              if (user.forename && !user.surename) {
                this.suggestions.names.add(user.forename);
              } else if (user.surename && !user.forename) {
                this.suggestions.names.add(user.surename);
              } else {
                this.suggestions.names.add(user.surename + " " + user.forename);
              }
            }
          });
        }
      }
      this.$forceUpdate();
    }
  },
  methods: {
    async submitForm() {
      this.busy = true;
      this.successMessage = null;
      this.errorMessage = null;
      this.form.contacts[this.organisation.organisationId] = Object.assign({}, this.contact);

      if (!this.form.organisationIds.includes(this.organisation.organisationId)) {
        this.form.organisationIds.push(this.organisation.organisationId);
      }

      let client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}deals/export`, this.form)
        .then((response) => {
          this.successMessage = response.data.message;
        })
        .catch((e) => {
          Console.error(e);
          this.errorMessage = e.response.data.message;
        })
        .finally(() => (this.busy = false));
    },
    resetForm() {
      // this.form.parentAccountId = null;
      this.form.reupload = false;
      this.form.contacts = {};
      this.form.organisationIds = [];

      this.contact.first_name = null;
      this.contact.last_name = null;
      this.contact.email = null;
      this.contact.mobile_number = null;
      this.contact.accountantName = null;
      this.contact.accountantEmail = null;

      this.successMessage = null;
      this.errorMessage = null;

      this.suggestions.phoneNumbers = new Set();
      this.suggestions.emails = new Set();
      this.suggestions.names = new Set();
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#freshsales-export-modal {
  .button-wrapper {
    width: 7rem;
    min-width: 7rem;
    text-align: right;
  }
  .suggestion-tags {
    display: flex;
    flex-flow: wrap;
    .badge {
      cursor: pointer;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}
</style>

