
<template>
  <b-modal
    id="create-organisation-modal"
    v-if="isAdmin"
    ref="createorgmodal"
    title="Create a new organisation"
    lazy
    hide-footer
    @keydown.native.enter="saveOrgaisation"
    @hidden="$emit('closeModal')"
  >
    <span class="error-message px-2 py-1" v-if="errorMessage">{{ errorMessage }}</span>

    <b-checkbox v-model="meFirstUser" class="mb-1" @change="setFirstUser(myUser)"
      >I am the first user</b-checkbox
    >

    <b-input-group prepend="First User" class="mb-2">
      <b-form-select v-model="selectedUser" :disabled="meFirstUser">
        <option v-for="user in users" :key="user.userId" :value="user">{{ user.email }}</option>
      </b-form-select>
    </b-input-group>

    <b-input-group prepend="Organisation Name" class="mb-2">
      <b-form-input
        :state="orgnameState"
        v-model="organisation.name"
        :disabled="busyLoading || myUser == {}"
      />
      <b-form-invalid-feedback :state="orgnameState"
        >Invalid organisation name.</b-form-invalid-feedback
      >
    </b-input-group>

    <b-select class="mb-2" v-model="selectedOrganisationType" :options="organisationTypes">
      <template #first>
        <b-form-select-option :value="null" disabled>Select Organisation Type</b-form-select-option>
      </template>
    </b-select>

    <div class="float-right">
      <b-button
        :disabled="myUser == {}"
        class="actionButton"
        v-if="!busyLoading"
        variant="primary"
        pill
        @click="saveOrgaisation"
        >Save</b-button
      >
      <b-button class="actionButton" pill v-else variant="primary">
        <Busy :size="1.3" :margin="0" />
      </b-button>

      <b-button
        class="actionButton"
        v-if="!busyLoading"
        :disabled="myUser == {}"
        pill
        @click="$refs.createorgmodal.hide()"
        >Cancel</b-button
      >
      <b-button class="actionButton" pill v-else>
        <Busy :size="1.3" :margin="0" />
      </b-button>
    </div>
  </b-modal>
</template>


<style lang="scss">
@import "@/styles/common.scss";

#create-organisation-modal {
  .actionButton {
    width: 80px;
    margin-left: 0.5em;
  }

  .error-message {
    background-color: $color-red-light2;
    color: white;
    border-radius: 12px;
  }
}
</style>

<script>
import Busy from "@/components/Busy";
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";

export default {
  name: "CreateOrganisationModal",
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Busy
  },
  data() {
    return {
      myUser: {},
      users: [],
      selectedUser: {},
      meFirstUser: true,

      organisation: {},
      orgnameState: null,
      busyLoading: false,
      organisationTypes: [
        { value: "ACCOUNTING_PRACTICE", text: "Accounting Practice" },
        { value: "COMPANY", text: "Company" },
        { value: "NOT_FOR_PROFIT", text: "Not for profit" },
        { value: "PARTNERSHIP", text: "Partnership" },
        { value: "SOLE_TRADER", text: "Sole Trader" }
      ],
      selectedOrganisationType: null,
      errorMessage: null
    };
  },
  watch: {
    showModal: function (value) {
      if (value) {
        this.$refs.createorgmodal.show();
      } else {
        this.$refs.createorgmodal.hide();
      }
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    setFirstUser(user) {
      if (this.meFirstUser) {
        this.selectedUser = Object.assign({}, user);
      }
    },

    async fetchUsers() {
      var token = await CognitoAuth.getCurrentUserAccessToken();
      var userId = TokenHelper.parseJwtToken(token).sub;
      this.busyLoading = true;

      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}users`);

        if (response.data.status === "success") {
          this.users = response.data.users;
          this.users.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1));
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].userId == userId) {
              this.myUser = Object.assign({}, this.users[i]);
            }
          }
          this.selectedUser = Object.assign({}, this.myUser);
        } else {
          this.showMessage("warning", "There was a problem getting the users.");
        }
      } catch {
        this.showMessage("warning", "There was a problem getting the users.");
      } finally {
        this.busyLoading = false;
      }
    },

    async saveOrgaisation() {
      this.errorMessage = null;
      this.orgnameState = null;
      if (!this.organisation.name || this.organisation.name.length < 2) {
        this.orgnameState = false;
        return;
      }

      this.busyLoading = true;
      this.organisation.organisationSource = "Reducer";
      this.organisation.firstUserId = this.selectedUser.userId;
      this.organisation.firstUserEmail = this.selectedUser.email;
      if (this.selectedOrganisationType) {
        this.organisation.organisationType = this.selectedOrganisationType;
      }

      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}organisations`, {
          organisation: this.organisation
        });

        if (response.data.status === "success") {
          this.$refs.createorgmodal.hide();
          this.showMessage(
            "success",
            "Organisation '" + this.organisation.name + "' created successfully"
          );
          this.$router.push({
            name: "organisation-data-upload",
            params: { organisationId: response.data.organisation.organisationId }
          });
        } else {
          this.errorMessage = "Organisation " + this.organisation.name + " could not be saved.";

          this.showMessage(
            "warning",
            "Organisation '" + this.organisation.name + "' could not be saved."
          );
        }
      } catch (err) {
        this.errorMessage = "Organisation " + this.organisation.name + " could not be saved.";
        this.showMessage(
          "warning",
          "Organisation '" + this.organisation.name + "' could not be saved."
        );
      } finally {
        this.busyLoading = false;
      }
    },

    showMessage(variant, message) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>