<template>
  <b-modal
    id="organisation-reextract-modal"
    ref="reextractOrg"
    v-model="showModal"
    lazy
    hide-footer
    hide-header
    :no-close-on-backdrop="busy"
  >
    <div id="organisation-reextract-modal-contents" class="p-3">
      <div class="w-100 text-center mb-4">
        <img v-if="source == 'Xero'" src="@/assets/images/xero.png" alt="Xero" />
        <img v-if="source == 'QuickBooks'" src="@/assets/images/qb.png" alt="QuickBooks" />
      </div>

      <div v-if="busy" class="text-center my-3">
        <Busy primary />
        <h5 class="mt-3">{{ busyText }}</h5>
      </div>

      <div v-else-if="message" class="my-4 text-center">
        <h4>{{ title }}</h4>
        <h5>{{ message }}</h5>
      </div>

      <div v-else-if="users && users.length > 1" class="my-4">
        <h5 class="mb-2 ml-2">Select a user to start the extract:</h5>
        <b-button
          pill
          variant="outline-secondary"
          class="w-100 user-row reducer-box-shadow p-2 px-4 text-left mt-2"
          @click="startExtract(user)"
          v-for="user in users"
          :key="user.userId"
        >
          <span class="float-left">
            <b v-if="user.forename || user.surname">{{ user.forename }} {{ user.surname }}:</b>
            {{ user.email }}
          </span>
          <font-awesome-icon
            v-b-popover.hover.top="'Start extract'"
            class="float-right mt-1"
            :icon="['fas', 'chevron-right']"
          />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#organisation-reextract-modal {
  .user-row {
    border: 1px solid $color-grey-lighter3 !important;
    &:hover {
      background-color: $color-grey-lighter5;
      color: $color-grey;
    }
  }
}
</style>

<script>
import Busy from "@/components/Busy";
import ApiHelper from "@/helper/apihelper";

export default {
  name: "OrganisationReextractModal",
  components: { Busy },
  data() {
    return {
      organisationId: null,
      source: null,
      showModal: false,
      title: null,
      message: null,
      busy: false,
      busyText: null,
      users: [],
      organisation: {}
    };
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    show(orgid, platform) {
      this.organisationId = orgid;
      this.source = platform;
      this.showModal = true;
      this.searchOrgansationExtractUsers();
    },

    async searchOrgansationExtractUsers() {
      this.busy = true;
      this.busyText = "Getting users";
      this.title = null;
      this.message = null;

      if (!this.organisationId) {
        this.title = "Error";
        this.message = "The organisation chosen has no organisation ID.";
        this.busy = false;
        return;
      }

      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}extracts/users?organisationId=${this.organisationId}`
        );

        if (response.data.status === "success") {
          this.users = response.data.users;
          this.organisation = Object.assign({}, response.data.organisation);
          if (this.users.length < 1) {
            this.title = "Organisation cannot be extracted";
            this.message =
              "There are no users connected to this organisation with an active connection. The organisation therefore cannot be extracted.";
          } else if (this.users.length == 1) {
            await this.startExtract(this.users[0]);
          }
        } else {
          this.title = "Error";
          this.message = "There has been an error while getting extract users";
          console.error("Error getting users: ", response);
        }
      } catch (e) {
        this.title = "Error";
        this.message = "There has been an error while getting extract users";
        console.error("There has been an error while getting extract users: ", e);
      } finally {
        this.busy = false;
        this.busyText = null;
      }
    },

    async startExtract(user) {
      this.busy = true;
      this.busyText = "Starting Extract";
      this.title = null;
      this.message = null;

      let platform = this.organisation.organisationSource == "QuickBooks" ? "quickBooks" : "xero";
      let client = await ApiHelper.http();
      await client
        .post(
          `${ApiHelper.endPoint()}extracts/start?userId=${
            user.userId
          }&platform=${platform}&organisationId=${this.organisationId}`
        )
        .then(() => {
          this.title = "Success";
          this.message = "The extract has been started";
        })
        .catch((e) => {
          if (e.response.status == 409) {
            this.title = "Extract in progress";
            this.message = "There's an extract in progress for this organisation already.";
          } else {
            this.title = "Error";
            this.message = "The extract could not be started";
            console.error("The extract could not be started: ", e);
          }
        })
        .finally(() => {
          this.busy = false;
          this.busyText = null;
        });
    }
  }
};
</script>
