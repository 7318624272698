<template>
  <div id="all-organisations">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <b-container fluid>
      <h1>
        Organisation List
        <b-button
          variant="outline-primary"
          class="mt-2 float-md-right"
          @click="createorgModal = true"
          >Create new organisation</b-button
        >
      </h1>

      <b-form class="reducer-filter">
        <b-row class="search-filter pb-3">
          <b-col cols="12" md="6">
            <b-row>
              <b-col cols="12" md="6" class="mt-2 mt-md-0">
                <b-form-group label="FROM LAST EXTRACT" label-for="startDate">
                  <b-input
                    id="startDate"
                    v-model="startDate"
                    class="reducer-input"
                    placeholder="YYYY-MM"
                    :disabled="tableIsBusy || neverExtracted"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mt-2 mt-md-0">
                <b-form-group label="TO LAST EXTRACT" label-for="endDate">
                  <b-input
                    id="endDate"
                    class="reducer-input"
                    v-model="endDate"
                    placeholder="YYYY-MM"
                    :disabled="tableIsBusy || neverExtracted"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-2 d-flex">
                <b-form-checkbox
                  class="mr-3"
                  :disabled="tableIsBusy"
                  v-model="excludeAdminExtracts"
                  @change="excludeAdminExtractsChanged()"
                  >EXCLUDE ADMIN EXTRACTS</b-form-checkbox
                >
                <b-form-checkbox
                  :disabled="tableIsBusy"
                  v-model="neverExtracted"
                  @change="neverExtractedChanged()"
                  >NEVER EXTRACTED</b-form-checkbox
                >
              </b-col>
            </b-row>
          </b-col>

          <b-col class="mt-2 mt-md-0">
            <b-form-group label-for="queryInput" label="SEARCH QUERY">
              <b-input-group>
                <b-form-input
                  id="queryInput"
                  ref="queryInput"
                  v-model="queryInput"
                  :disabled="tableIsBusy"
                  class="reducer-input"
                  placeholder="Organisation name / user email"
                  @keydown.native.enter="validateAndRunQuery()"
                />
                <b-input-group-append>
                  <b-btn
                    :disabled="tableIsBusy"
                    variant="primary"
                    class="reducer-btn"
                    @click="validateAndRunQuery()"
                    >OK</b-btn
                  >
                  <b-btn :disabled="tableIsBusy" @click="clearQuery">Clear</b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="text-right mt-2">
              <b-badge class="querySelection mr-2" @click="setExtractInputs(1)"
                >LAST 2 MONTHS</b-badge
              >
              <b-badge class="querySelection" @click="setExtractInputs(0)">THIS MONTH</b-badge>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-invalid-feedback :state="datesErrorMessage == null">
              {{ datesErrorMessage }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="filter-border">
          <b-col cols="7" class="pr-5">
            <b-form-group label="RESULT FILTER" label-for="resultSearch">
              <b-form-input
                id="resultSearch"
                v-model="resultFilter"
                class="reducer-search-input"
                placeholder="Organisation name / last extract date / first user"
                @change="filteredResult"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-checkbox v-model="reportStateFilterOn" class="mb-1"
              >ENABLE REPORT STATE FILTER</b-checkbox
            >
            <multiselect
              v-model="selectedReportStates"
              :options="reportStates"
              :disabled="!reportStateFilterOn"
              multiple
              taggable
              placeholder="Select area"
            />
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col cols="7" class="pr-5">
            <b-row class="align-items-end">
              <b-col cols="8">
                <label>SPOTLIGHT SPEND FILTER</label>
                <multiselect
                  v-model="spotlightFilter.spendAreas"
                  :options="spotlightAreas"
                  multiple
                  taggable
                  placeholder="Select area"
                />
              </b-col>
              <b-col>
                <b-input
                  v-model="spotlightFilter.minSpend"
                  id="spotlight-min-spend"
                  type="number"
                  placeholder="Minimum spend"
                  class="reducer-input"
                  min="0"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="5">
            <div class="d-flex align-items-center justify-content-between">
              <b-checkbox v-model="attachmentFilterOn" class="mb-1">
                ENABLE SPOTLIGHT ATTACHMENTS FILTER
              </b-checkbox>
              <b-checkbox v-model="spotlightFilter.noAttachments" class="text-nowrap">
                NO ATTACHMENTS
              </b-checkbox>
            </div>
            <multiselect
              v-model="spotlightFilter.attachmentAreas"
              :options="spotlightAreas"
              :disabled="!attachmentFilterOn"
              multiple
              taggable
              placeholder="Select area"
            />
          </b-col>
        </b-row>
      </b-form>
      <b-row class="pt-3">
        <b-col>
          <p v-if="filteredResult.length > 0" class="mt-2 mb-1">
            {{ filteredResult.length }} Organisation(s)
          </p>
          <b-button
            v-if="filteredResult.length > 0"
            size="sm"
            class="mb-3"
            variant="outline-secondary"
            @click="copyOrgNames"
          >
            <font-awesome-icon :icon="['far', 'clipboard']" /> Copy org. names
          </b-button>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-pagination
            v-if="filteredResult.length > perPage && !tableIsBusy"
            class="reducer-pagination align-self-end mb-3"
            align="right"
            :total-rows="filteredResult.length"
            :per-page="perPage"
            v-model="currentPage"
            :first-text="'\u00AB'"
            :prev-text="'\u2039'"
            :next-text="'\u203A'"
            :last-text="'\u00BB'"
            :ellipsis-text="'\u2026'"
          />
        </b-col>
        <b-col sm="12">
          <b-table
            ref="orgTable"
            :current-page="currentPage"
            :items="filteredResult"
            :fields="fields"
            primary-key="organisationId"
            :busy="tableIsBusy"
            show-empty
            :empty-text="emptyText"
            busy.sync="loading"
            :per-page="perPage"
            sort-direction="desc"
          >
            <template v-slot:table-busy>
              <Busy primary text="Loading..." />
            </template>
            <template v-slot:cell(name)="row"
              ><span
                v-clipboard:copy="orgShortName(row.value)"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
                class="org-name"
                >{{ orgShortName(row.value) }}</span
              ></template
            >
            <template v-slot:cell(firstUserEmail)="row">
              {{ row.value }}
              <span
                v-if="row.item.lastExtractUserEmail && row.item.lastExtractUserEmail != row.value"
                class="d-block small text-muted pt-1"
                v-b-popover.hover.top="'Most Recent User'"
                >Last: {{ row.item.lastExtractUserEmail }}</span
              >
              <span
                v-if="row.item.lastExtractRunByEmail && row.item.lastExtractRunByEmail != row.value"
                class="d-block small text-muted pt-1"
                v-b-popover.hover.top="'Last Extract Run By'"
                >By: {{ row.item.lastExtractRunByEmail }}</span
              >
            </template>
            <template v-slot:cell(organisationSource)="row">
              <OrganisationSourceIcon :source="row.value" />
            </template>

            <template v-slot:cell(totalCommission)="row">
              <div v-if="row.item.state == 'REPORT_COMPLETE' && row.item.totalCommission >= 0">
                {{ $currencySymbol + formatNumberToDisplay(row.item.totalCommission) }}
              </div>
            </template>

            <template v-slot:cell(totalSavings)="row">
              <div v-if="row.item.state == 'REPORT_COMPLETE' && row.item.totalSavings >= 0">
                {{ $currencySymbol + formatNumberToDisplay(row.item.totalSavings) }}
              </div>
            </template>

            <template v-slot:cell(saving)="row">
              <div v-if="row.value != null && row.value.totalSavings">
                <b-badge
                  class="savings-badge"
                  v-for="(typeSaving, key) in row.value.supplyTypeSavings"
                  :key="key"
                  >{{
                    formatSupplyType(key) +
                      ": " +
                      $currencySymbol +
                      formatNumberToDisplay(typeSaving)
                  }}</b-badge
                >
              </div>
            </template>

            <template v-slot:cell(status)="row">
              <b-badge
                v-if="row.item.areasThatNeedMoreData"
                class="badge-block"
                variant="primary"
                v-b-tooltip="'High spend and not enough data'"
              >
                Request bills: Energy
              </b-badge>
              <b-badge
                v-if="row.item.limitedExtract"
                class="badge-block"
                v-b-tooltip="'Too much data to download in once extract'"
              >
                Limited data
              </b-badge>
              <b-badge
                v-if="row.item.lastExtractJobState == 'MANUAL'"
                class="badge-block"
                variant="primary"
              >
                {{
                  row.item.lastExtractRunByEmail &&
                  !row.item.lastExtractRunByEmail.includes("reducer")
                    ? "Client"
                    : "Admin"
                }}
                upload
              </b-badge>
              <b-badge v-if="row.item.state != null" :variant="reportStatusVariant(row.item)">
                {{ savingsStateDescription(status(row.item)) }}
              </b-badge>
            </template>

            <template v-slot:cell(shared)="row">
              <div v-if="hasReport(row.item)">
                <font-awesome-icon
                  :icon="['fas', 'share-alt-square']"
                  class="shared"
                  v-if="row.item.shared"
                  v-b-popover.hover.top="'First shared on ' + formatDate(row.item.shared)"
                />
                <font-awesome-icon :icon="['fas', 'share-alt']" class="not-shared" v-else />
              </div>
              <div v-else />
            </template>

            <template v-slot:cell(auto)="row">
              <b-badge
                v-if="row.item.saving && row.item.saving.autoPublished"
                variant="auto"
                v-b-popover.hover.top="'Processed by Auto Publisher'"
                >AP</b-badge
              >
            </template>

            <template v-slot:cell(actions)="row">
              <b-dropdown text="" lazy>
                <organisation-tabs
                  :organisation-id="row.item.organisationId"
                  :archived="status(row.item) == 'ARCHIVED'"
                >
                  <b-dropdown-item @click.stop="showUserOrganisationModal(row.item)">
                    <font-awesome-icon :icon="['fas', 'user']" class="text-muted mr-2 pr-1" />
                    Add user
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="canExtract(row.item.organisationSource)"
                    @click="
                      $refs.orgreextract.show(row.item.organisationId, row.item.organisationSource)
                    "
                  >
                    <font-awesome-icon :icon="['fas', 'magic']" class="text-muted mr-2" />
                    Start Extract
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="
                      row.item.state != null && row.item.organisationSource == 'ArmstrongWatson'
                    "
                    @click="copyAwFields(row.item)"
                  >
                    <font-awesome-icon :icon="['far', 'clipboard']" class="text-muted mr-2" />
                    Copy AW data
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="hasReport(row.item)"
                    v-b-modal.freshsales-export-modal
                    @click="freshSalesExportOrg = row.item"
                  >
                    <font-awesome-icon :icon="['fas', 'magic']" class="text-muted mr-2" />
                    Export to Freshsales
                  </b-dropdown-item>
                  <!-- <b-dropdown-item
                    @click="$refs.numberModal.show(row.item, row.item.organisationId)"
                  >
                    <font-awesome-icon :icon="['fas', 'phone-alt']" class="text-muted mr-2" />
                    Make a call
                  </b-dropdown-item> -->
                </organisation-tabs>
              </b-dropdown>
            </template>

            <template v-slot:cell(lastExtractJob)="row">{{ formatDate(row.value) }}</template>
            <template v-slot:cell(savingsDate)="row">{{ formatDate(row.value) }}</template>
            <template v-slot:cell(spotlightSummary)="row">
              <b-badge v-if="row.value && !hasInvoices(row.item)" variant="light"
                >No matches</b-badge
              >
              <b-badge
                v-if="row.value && hasInvoices(row.item) && !hasAttachments(row.item)"
                class="mb-1"
                >No attachments</b-badge
              >
              <div v-if="row.value && row.value.spendPerGroup" class="d-flex flex-column">
                <b-badge
                  v-for="area in Object.keys(row.value.spendPerGroup)"
                  :key="area"
                  :class="[
                    'savings-badge pb-1 border-none',
                    { 'has-energy-att': hasAreaAttachments(row.item, 'Energy') && area == 'Energy' }
                  ]"
                >
                  <font-awesome-icon
                    v-if="row.value.invoicesWithAttachmentsPerGroup[area]"
                    class="mr-1"
                    :icon="['fas', 'paperclip']"
                  />
                  {{ spotlightDescription(row.value, area) }}
                </b-badge>
              </div>
            </template>

            <template v-slot:cell(totalScore)="row">
              <SpotlightScoreBadge
                v-if="row.item.spotlightSummary"
                :id="row.item.organisationId"
                :org-id="row.item.organisationId"
                :score="row.item.spotlightSummary.score"
                :spotlight-summary="row.item.spotlightSummary"
              />
            </template>
            <template v-slot:cell(creditScore)="row">
              <CreditScoreBadge v-if="row.value" :score="row.value" />
            </template>
          </b-table>
          <b-pagination
            v-if="filteredResult.length > perPage && !tableIsBusy"
            class="reducer-pagination d-flex float-right"
            :total-rows="filteredResult.length"
            :per-page="perPage"
            v-model="currentPage"
            :first-text="'\u00AB'"
            :prev-text="'\u2039'"
            :next-text="'\u203A'"
            :last-text="'\u00BB'"
            :ellipsis-text="'\u2026'"
          />
        </b-col>
      </b-row>
      <!-- Add user to organisation modal -->
      <b-modal
        title="Add user to organsation"
        v-model="userOrganisationModal.show"
        @ok="submitUserOrganisationModal"
      >
        <p>Please enter user ID to connect to {{ userOrganisationModal.organisation.name }}</p>

        <b-input
          v-model="userOrganisationModal.userId"
          @input="userOrganisationModal.valid = null"
          placeholder="User ID"
        />

        <b-form-invalid-feedback :state="userOrganisationModal.valid"
          >Invalid user ID entered.</b-form-invalid-feedback
        >
      </b-modal>
      <b-modal
        lazy
        id="all-orgs-warning"
        hide-header
        @ok="fetchData"
        ok-title="Just do it already"
        cancel-title="Abort mission"
      >
        <div class="m-3">
          <h5>
            Woah there friend, that query gets every organisation. That's a lot of data. Your query
            should probably be more specific.
          </h5>
        </div>
      </b-modal>
      <!-- Export to Freshsales modal -->
      <FreshsalesExportModal :organisation="freshSalesExportOrg" />
      <CreateOrganisationModal
        :isAdmin="true"
        :showModal="createorgModal"
        @closeModal="createorgModal = false"
      />
      <OrganisationReextractModal ref="orgreextract" />
      <!-- <CallNumberModal ref="numberModal" /> -->
    </b-container>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";
import Console from "@/console";
import IDHelper from "@/helper/idhelper";
import FreshsalesExportModal from "@/components/modals/FreshsalesExportModal";
import CreateOrganisationModal from "@/components/modals/CreateOrganisationModal";
import moment from "moment";
import OrganisationReextractModal from "@/components/modals/OrganisationReextractModal";
import OrganisationTabs from "@/components/nav/OrganisationTabs";
import SpotlightScoreBadge from "@/components/spotlight/SpotlightScoreBadge";
import CreditScoreBadge from "@/components/CreditScoreBadge";
import OrganisationSourceIcon from "@/components/OrganisationSourceIcon";

export default {
  name: "all-organisations",
  components: {
    Busy,
    FreshsalesExportModal,
    CreateOrganisationModal,
    OrganisationReextractModal,
    OrganisationTabs,
    SpotlightScoreBadge,
    CreditScoreBadge,
    OrganisationSourceIcon
  },
  data() {
    return {
      title: "Organisations",
      emptyText: "Submit a query to begin",
      createorgModal: false,
      organisations: [],

      fields: [
        { key: "actions", label: "", class: "text-center align-middle" },
        {
          key: "name",
          label: "Name",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "firstUserEmail",
          label: "First User",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "lastExtractJob",
          label: "Last Extract",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "organisationSource",
          label: "",
          class: "text-center align-middle"
        },
        {
          key: "totalCommission",
          label: "Comms",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "totalSavings",
          label: "Savings",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "saving",
          label: "Saving Areas",
          formatter: (value, key, item) => {
            return item.state == "REPORT_COMPLETE" ? item.saving : null;
          },
          class: "align-middle"
        },

        {
          key: "savingsDate",
          label: "Report date",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "shared",
          label: "",
          class: "text-center align-middle px-0"
        },
        {
          key: "spotlightSummary",
          label: "Spotlight",
          class: "text-center align-middle"
        },
        {
          key: "status",
          label: "Status",
          class: "text-center align-middle"
        },
        {
          key: "totalScore",
          label: "Score",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "creditScore",
          label: "Credit Score",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "auto",
          label: "",
          class: "text-center align-middle p-1"
        }
      ],
      perPage: 70,
      currentPage: 1,
      tableIsBusy: false,
      userOrganisationModal: {
        show: false,
        valid: null,
        userId: null,
        organisation: {}
      },
      freshSalesExportOrg: null,

      queryInput: null,
      neverExtracted: false,
      excludeAdminExtracts: false,
      startDate: null,
      endDate: null,
      datesErrorMessage: null,

      resultFilter: null,
      attachmentFilterOn: false,
      reportStateFilterOn: false,

      spotlightAreas: ["Energy", "Water", "Card Processing", "Telecoms", "Waste"],
      spotlightFilter: {
        attachmentAreas: [],
        spendAreas: [],
        minSpend: null,
        noAttachments: false
      },

      selectedReportStates: [],
      reportStates: [
        "Data gathering error",
        "Queued",
        "Gathering data",
        "Analysis not yet requested",
        "Report complete",
        "Report production in progress",
        "New report production in progress",
        "No Savings: Other",
        "No Savings: Insufficient data",
        "No Savings: No matches"
      ]
    };
  },
  computed: {
    filteredResult() {
      let result = this.organisations;
      let resultFilterOn = this.resultFilter && this.resultFilter.length > 1;
      let spendFilterOn = this.spotlightFilter.spendAreas.length && this.spotlightFilter.minSpend;
      let attachmentFilterOn = this.attachmentFilterOn || this.spotlightFilter.noAttachments;

      if (
        resultFilterOn ||
        attachmentFilterOn ||
        this.reportStateFilterOn ||
        spendFilterOn ||
        this.excludeAdminExtracts
      ) {
        result = this.organisations.filter(o => {
          let showResult = true;

          // Filter on report states
          if (this.reportStateFilterOn && showResult) {
            // Don't show orgs with no state
            showResult = false;
            if (
              o.state &&
              this.selectedReportStates.length > 0 &&
              this.selectedReportStates.includes(this.savingsStateDescription(this.status(o)))
            ) {
              showResult = true;
            }
          }

          // Filter on organisation name search / last extract date
          if (showResult && resultFilterOn) {
            let resultStr = this.resultFilter.toLowerCase();
            showResult =
              (o.name && o.name.toLowerCase().includes(resultStr)) ||
              (o.lastExtractJob && this.formatDate(o.lastExtractJob).includes(resultStr)) ||
              (o.firstUserEmail && o.firstUserEmail.toLowerCase().includes(resultStr)) ||
              (o.lastExtractUserEmail &&
                o.lastExtractUserEmail.toLowerCase().includes(resultStr)) ||
              (o.lastExtractRunByEmail &&
                o.lastExtractRunByEmail.toLowerCase().includes(resultStr));
          }

          // Filter on spotlight attachments
          if (showResult && attachmentFilterOn) {
            showResult = false;
            if (!this.spotlightFilter.attachmentAreas.length) {
              let hasAttachments = this.hasAttachments(o);
              showResult = this.spotlightFilter.noAttachments ? !hasAttachments : hasAttachments;
            } else {
              showResult = this.spotlightFilter.attachmentAreas.every(a => {
                let hasAttachments = this.hasAreaAttachments(o, a);
                return this.spotlightFilter.noAttachments ? !hasAttachments : hasAttachments;
              });
            }
          }

          // Filter on spotlight spend
          if (showResult && spendFilterOn) {
            showResult = false;
            if (o.spotlightSummary && o.spotlightSummary.spendPerGroup) {
              showResult = this.spotlightFilter.spendAreas.every(a => {
                if (o.spotlightSummary.spendPerGroup[a]) {
                  return o.spotlightSummary.spendPerGroup[a] >= this.spotlightFilter.minSpend;
                } else return false;
              });
            }
          }

          // Filter admin extracts
          if (showResult && this.excludeAdminExtracts) {
            if (o.lastExtractRunByEmail) {
              showResult = !o.lastExtractRunByEmail.includes("@reducer.co.uk");
            }
            if (showResult && o.lastExtractUserEmail) {
              showResult = !o.lastExtractUserEmail.includes("@reducer.co.uk");
            }
          }

          return showResult;
        });
      }

      this.resetPagination();
      return result;
    }
  },
  methods: {
    formatDate: FormatHelper.formatDate,
    formatDateTimeToMin: FormatHelper.formatDateTimeToMin,
    orgShortName: FormatHelper.orgShortName,
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,

    resetPagination() {
      this.currentPage = 1;
    },
    async fetchData() {
      this.tableIsBusy = true;
      this.emptyText = "No organisations match that query";
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}organisations${this.getOrganisationParams()}`
        );

        if (response.data.status === "success") {
          Console.log(response);
          this.organisations = response.data.organisations;
          const organisationLookup = {};
          for (const o of this.organisations) {
            // Un-nest spotlight score to make sorting easier
            if (o.spotlightSummary && o.spotlightSummary.score) {
              o.totalScore = o.spotlightSummary.score.totalScore;
            }
            organisationLookup[o.organisationId] = o;
            if (organisationLookup[o.organisationId]) {
              organisationLookup[o.organisationId].state = o.state;

              if (o.saving) {
                // Un-nest totalSavings to make sorting easier on table
                o.totalCommission = o.saving.totalCommission;
                o.totalSavings = o.saving.totalSavings;
                organisationLookup[o.organisationId].savingsDate = o.saving.createdDate;
                organisationLookup[o.organisationId].shared = o.saving.shared;
              }
            }
          }
        } else {
          this.showWarning("There was a problem getting organisations");
        }
      } catch (err) {
        Console.error(err);

        this.showWarning("There was a problem getting organisations");
      } finally {
        this.tableIsBusy = false;
      }
    },

    hasReport(organisation) {
      return organisation.saving
        ? organisation.saving.state === "complete"
        : organisation.state === "REPORT_COMPLETE";
    },

    reportStatusVariant(organisation) {
      var variant = "warning";
      switch (this.status(organisation)) {
        case "NOT_REQUESTED":
        case "ERROR":
          variant = "danger";
          break;
        case "REPORT_COMPLETE":
          variant = "pink";
          break;
        case "NOSAVINGS":
        case "NOSAVINGSDATA":
        case "NOSAVINGSMATCHES":
          variant = "secondary";
          break;
      }

      return variant;
    },

    status(organisation) {
      var status = organisation.state;
      const report = organisation.saving;

      if (status !== "ERROR" && status !== "GATHERING" && report) {
        if (report.archived) {
          status = "ARCHIVED";
        } else if (report.state === "noneFound") {
          status = "NOSAVINGS";
        } else if (report.state === "noneFoundInsufficientData") {
          status = "NOSAVINGSDATA";
        } else if (report.state === "noneFoundNoMatches") {
          status = "NOSAVINGSMATCHES";
        }
      }

      return status;
    },

    savingsStateDescription(state) {
      var string = null;

      if (state != null) {
        switch (state) {
          case "ERROR":
            string = "Data gathering error";
            break;
          case "QUEUED":
            string = "Queued";
            break;
          case "GATHERING":
            string = "Gathering data";
            break;
          case "NOT_REQUESTED":
            string = "Analysis not yet requested";
            break;
          case "REPORT_COMPLETE":
            string = "Report complete";
            break;
          case "REPORT_GENERATION":
            string = "Report production in progress";
            break;
          case "ARCHIVED":
            string = "New report production in progress";
            break;
          case "NOSAVINGS":
            string = "No Savings: Other";
            break;
          case "NOSAVINGSDATA":
            string = "No Savings: Insufficient data";
            break;
          case "NOSAVINGSMATCHES":
            string = "No Savings: No matches";
            break;
        }
      }

      return string;
    },

    hasInvoices(org) {
      return org.spotlightSummary != null && org.spotlightSummary.totalMatchedInvoices > 0;
    },

    hasAttachments(org) {
      return (
        org.spotlightSummary != null && org.spotlightSummary.totalMatchedInvoicesWithAttachments > 0
      );
    },

    hasAreaAttachments(org, area) {
      if (this.hasAttachments(org) && org.spotlightSummary.invoicesWithAttachmentsPerGroup) {
        return (
          org.spotlightSummary.invoicesWithAttachmentsPerGroup[area] &&
          org.spotlightSummary.invoicesWithAttachmentsPerGroup[area] > 0
        );
      } else return false;
    },

    spotlightDescription(summary, area) {
      return (
        this.formatSupplyType(area) +
        ": " +
        this.$currencySymbol +
        this.formatNumberToDisplay(summary.spendPerGroup[area]) +
        " spend"
      );
    },

    getOrganisationParams() {
      let params = ApiHelper.appendParam(params, "query", this.queryInput);
      params = ApiHelper.appendParam(params, "summarised", true);
      params = ApiHelper.appendParam(params, "includeSavingsData", "true");
      params = ApiHelper.appendParam(params, "includeSavingsTotal", "true");
      params = ApiHelper.appendParam(params, "neverExtracted", this.neverExtracted);
      if (this.startDate) {
        params = ApiHelper.appendParam(params, "startLastExtractMonth", this.startDate);
      }
      if (this.endDate) {
        params = ApiHelper.appendParam(params, "endLastExtractMonth", this.endDate);
      }
      return params;
    },

    clearQuery() {
      this.queryInput = null;
      this.startDate = null;
      this.endDate = null;
      this.spotlightFilter.spendAreas = [];
      this.spotlightFilter.attachmentAreas = [];
      this.selectedReportStates = [];
    },

    validateAndRunQuery() {
      this.datesErrorMessage = null;
      var today = new Date()
        .toJSON()
        .slice(0, 7)
        .replace(/-/g, "-");

      if (!this.endDate && !this.startDate && !this.queryInput && !this.neverExtracted) {
        this.$bvModal.show("all-orgs-warning");
        return;
      }
      if (this.endDate || this.startDate) {
        if (
          (this.startDate && !moment(this.startDate, "YYYY-MM", true).isValid()) ||
          (this.endDate && !moment(this.endDate, "YYYY-MM", true).isValid())
        ) {
          this.datesErrorMessage = "Enter a valid Start and End date";
          return;
        } else if (
          this.endDate &&
          this.startDate &&
          moment(this.startDate, "YYYY-MM") > moment(this.endDate, "YYYY-MM")
        ) {
          this.datesErrorMessage = "Start date must be equal or earlier than end date";
          return;
        } else if (this.startDate > today) {
          this.datesErrorMessage = "Start date cannot be in the future";
          return;
        }
      }

      this.fetchData();
    },

    showUserOrganisationModal(organisation) {
      this.userOrganisationModal.userId = this.userOrganisationModal.valid = null;
      this.userOrganisationModal.organisation = organisation;
      this.userOrganisationModal.show = true;
    },

    async submitUserOrganisationModal(bvModalEvt) {
      if (IDHelper.isValidUuid(this.userOrganisationModal.userId)) {
        this.addUserOrganisation(
          this.userOrganisationModal.userId,
          this.userOrganisationModal.organisation.organisationId
        );
      } else {
        bvModalEvt.preventDefault();
        this.userOrganisationModal.valid = false;
      }
    },

    async addUserOrganisation(userId, orgId) {
      let client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}users/${userId}/organisations/${orgId}`)
        .then(response => {
          Console.error(response);
          this.showSuccess("Successfully added user to organisation");
        })
        .catch(err => {
          Console.error(err);
          this.showWarning("There was a problem adding user to organisation");
        });
    },

    copyOrgNames() {
      if (this.filteredResult && this.filteredResult.length > 0) {
        let toCopy = "";
        this.filteredResult.forEach(o => {
          toCopy += this.orgShortName(o.name) + "\n";
        });

        this.$copyText(toCopy);
        this.copySuccess();
      } else {
        this.copyError();
      }
    },
    copyAwFields(org) {
      var id = org.shortCode ? org.shortCode : "";
      var office = org.organisationType ? org.organisationType : "";
      var partner = "";
      var manager = "";
      var clientName = "";
      var clientEmail = "";
      var savings = "";

      if (org.sourceUsers) {
        for (var i = 0, l = org.sourceUsers.length; i < l; i++) {
          var user = org.sourceUsers[i];

          if (user.forename && user.forename.includes("Partner")) {
            partner = user.email;
          }
          if (user.forename && user.forename.includes("Manager")) {
            manager = user.email;
          }
          if (user.forename && user.forename.includes("Client")) {
            clientEmail = user.email;
            clientName = user.forename.split(" (Client)")[0];
            clientName = clientName.split(" from")[0];
          }
        }
      }

      if (org.saving) {
        savings = org.saving.totalSavings;
      }

      var armstrongWatsonString =
        org.name +
        "," +
        id +
        "," +
        office +
        "," +
        manager +
        "," +
        partner +
        "," +
        clientName +
        "," +
        clientEmail +
        "," +
        savings;

      this.$copyText(armstrongWatsonString);
      this.copySuccess();
    },
    formatSupplyType(type) {
      if (type == "CARD_PAYMENTS" || type == "Card Processing") {
        return "PP";
      } else if (type && type.length > 3) {
        return type.substring(0, 3).toUpperCase();
      } else {
        return type.toUpperCase();
      }
    },
    setExtractInputs(monthsBack) {
      if (!this.tableIsBusy && !this.neverExtracted) {
        var today = new Date();
        this.endDate = today.toISOString().slice(0, 7);
        today.setMonth(today.getMonth() - monthsBack);
        this.startDate = today.toISOString().slice(0, 7);
      }
    },
    neverExtractedChanged() {
      if (this.neverExtracted) {
        this.startDate = null;
        this.endDate = null;
      }
      if (this.excludeAdminExtracts) {
        this.excludeAdminExtracts = false;
      }
    },
    excludeAdminExtractsChanged() {
      if (this.neverExtracted) {
        this.neverExtracted = false;
      }
    },
    canExtract(source) {
      return source == "Xero" || source == "QuickBooks";
    },

    copySuccess() {
      this.showSuccess("Copied to clipboard.");
    },
    copyError() {
      this.showWarning("Couldn't copy.");
    },

    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    showSuccess(message) {
      EventBus.$emit("show-toast", { message: message, variant: "success" });
    }
  }
};
</script>

<style lang="scss">
#all-organisations {
  @import "@/styles/common.scss";
  @include badge-styles;

  .querySelection {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .org-name {
    cursor: pointer !important;
  }

  .container-fluid {
    width: 98%;
  }
  .search-filter .form-group {
    margin: 0;
  }

  .shared {
    color: $color-green;
  }
  .not-shared {
    color: $color-font-para;
  }

  .badge-limited {
    background-color: $color-blue-lighter6;
  }
  .badge-auto {
    background-color: $color-blue-darker2;
    color: white;
  }
  .badge-block:not(:only-child) {
    display: block;
    width: min-content;
    margin: auto;
    margin-bottom: 0.2rem;
  }
  .badge-inline {
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
  .savings-badge {
    font-size: 60%;
    background-color: white !important;
    border: 1px solid $color-grey-lighter2;
    padding: 4px;
    margin-right: 2px;
    &.has-energy-att {
      border-color: $color-bright-green;
      color: $color-bright-green;
    }
  }
  th {
    text-align: center !important;
  }
  .border-none {
    border: none;
  }
}
</style>
