<template>
  <div v-if="score" :class="'spotlight-score-badge d-inline ' + colorIndex">
    <b-badge :id="'score-badge-' + id" class="main-badge">
      {{ score.totalScore }}
    </b-badge>

    <b-badge
      v-if="commission"
      variant="success"
      class="ml-2"
      v-b-popover.hover="'Estimated commission'"
    >
      {{ formatCurrency(commission) }} est.
    </b-badge>

    <!-- Individual score popover -->
    <b-popover
      v-if="id && !disabled && score"
      :custom-class="'spotlight-score-popover ' + colorIndex"
      :target="'score-badge-' + id"
      triggers="hover focus"
    >
      <!-- Header -->
      <template #title>
        <h5 class="m-0">{{ scoreHeader }}</h5>
        <span class="text-uppercase">
          {{ "Spotlight score of " + score.totalScore }}
          <router-link
            v-if="orgId"
            class="text-white"
            :to="{
              name: 'spotlight',
              params: { organisationId: orgId }
            }"
            target="_blank"
          >
            <font-awesome-icon :icon="['fas', 'external-link']" class="ml-1" />
          </router-link>
        </span>
        <font-awesome-icon class="header-icon" :icon="icon" />
      </template>

      <div v-if="score">
        <!-- Score stats -->
        <b class="text-uppercase small text-muted">Stats for scores of {{ score.scoreRange }}:</b>
        <ul class="pl-4 mb-0">
          <li>
            <b>{{ formatCurrency(score.estimatedCommission) }}</b> average revenue
          </li>
          <li>{{ score.estimatedReportChance }}% chance of a report</li>
          <li>{{ score.scoreProbability }}% chance of receiving this score</li>
        </ul>

        <!-- Group stats -->
        <ul class="mb-0 list-unstyled mt-2">
          <b v-if="areaScores.noMatches" class="text-muted small text-uppercase"
            >No matches found</b
          >
          <b v-else-if="areaScores.noAttachments" class="text-muted small text-uppercase"
            >No attachments found</b
          >
          <div v-if="areaScores['0-1'].length">
            <b class="text-muted small text-uppercase">Poor data:</b>
            <b-badge v-for="area in areaScores['0-1']" :key="area" class="ml-1">{{ area }}</b-badge>
          </div>
          <li v-if="areaScores['2'].length">
            <b class="text-muted small text-uppercase">Good data:</b>
            <b-badge v-for="area in areaScores['2']" :key="area" class="ml-1">{{ area }}</b-badge>
          </li>
          <li v-if="areaScores['3'].length">
            <b class="text-muted small text-uppercase">Excellent data:</b>
            <b-badge v-for="area in areaScores['3']" :key="area" class="ml-1">{{ area }}</b-badge>
          </li>
        </ul>
      </div>
    </b-popover>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SpotlightScoreBadge",
  props: {
    score: Object,
    spotlightSummary: Object, 
    id: String,
    orgId: String,
    disabled: Boolean, // disable popover
    commission: Number // display commission badge
  },
  computed: {
    scoreHeader() {
      if (this.score.totalScore < 10) {
        return "Poor";
      } else if (this.score.totalScore < 25) {
        return "Low";
      } else if (this.score.totalScore < 40) {
        return "Okay";
      } else if (this.score.totalScore < 60) {
        return "Good";
      } else if (this.score.totalScore < 85) {
        return "Excellent";
      } else {
        return "Fantastic";
      }
    },
    icon() {
      if (this.score.totalScore < 25) {
        return ["fas", "thumbs-down"];
      } else if (this.score.totalScore < 40) {
        return ["fas", "fish"];
      } else if (this.score.totalScore < 60) {
        return ["fas", "whale"];
      } else if (this.score.totalScore < 85) {
        return ["fas", "narwhal"];
      } else {
        return ["fas", "unicorn"];
      }
    },

    colorIndex() {
      if (this.score || this.average) {
        let className = "viridis-color-";
        let i = (this.score.totalScore - (this.score.totalScore % 5)) / 5;
        return className + i;
      } else return "";
    },

    areaScores() {
      let stats = {
        noAttachments: false,
        noMatches: false,
        "0-1": [],
        "2": [],
        "3": []
      };

      if (this.spotlightSummary) {
        stats.noMatches = !this.spotlightSummary.totalMatchedInvoices;
        stats.noAttachments = !this.spotlightSummary.totalMatchedInvoicesWithAttachments;
      }

      if (this.score && this.score.scorePerGroup) {
        Object.entries(this.score.scorePerGroup).forEach(([area, score]) => {
          if (score < 2) {
            stats["0-1"].push(area == "Card Processing" ? "PP" : area);
          } else {
            stats[score].push(area == "Card Processing" ? "PP" : area);
          }
        });
      }

      return stats;
    }
  },
  methods: {
    formatCurrency: FormatHelper.formatPoundToDisplay
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.spotlight-score-badge {
  @include badge-styles;
  .main-badge {
    cursor: default;
    font-weight: normal;
    color: white !important;
    width: 1.4rem !important;
    height: 1.3rem !important;
    padding: 0.25rem !important;
  }
  &.viridis-color-20 .main-badge {
    width: 1.7rem !important;
  } 
}

.spotlight-score-popover {
  .popover-header {
    position: relative;
    h5 {
      font-size: 15px;
      color: white !important;
    }
    span {
      font-size: 10px;
      color: white !important;
      font-weight: normal;
    }
    .header-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: snow;
      opacity: 0.7;
      font-size: 16px;
    }
  }

  .popover-body {
    color: $color-font-headings;
    font-size: 13px;
    background-color: rgb(255, 255, 255);
    b {
      font-weight: 600;
    }
  }

  .list-unstyled {
    .badge {
      border: 1px solid $color-blue-darker1;
      color: $color-blue-darker1;
      background-color: transparent;
    }
  }
}

@for $i from 1 through 21 {
  $main-score-color: nth($viridis_palette, $i);
  .spotlight-score-badge.viridis-color-#{$i - 1} {
    .main-badge {
      background-color: $main-score-color !important;
    }
  }
  .spotlight-score-popover.viridis-color-#{$i - 1} {
    .popover-header {
      background-color: $main-score-color !important;
      @if $i < 3 {
        h5,
        span,
        a {
          color: $color-font-para !important;
        }
        svg {
          opacity: 1;
        }
      }
    }
  }
}
</style>
