<template>
  <div v-if="score" class="credit-score-badge">
    <b-badge :class="creditRating.color" v-b-popover.hover.top="'Credit score: ' + score">
      {{ creditRating.text }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: "CreditScoreBadge",
  props: {
    score: Number
  },
  computed: {
    creditRating() {
      if (this.score) {
        if (this.score < 30) {
          return {
            text: "High risk",
            color: "red"
          };
        } else if (this.score < 51) {
          return {
            text: "Moderate risk",
            color: "orange"
          };
        } else if (this.score < 71) {
          return {
            text: "Limited risk",
            color: "yellow"
          };
        } else {
          return {
            text: "Low risk",
            color: "green"
          };
        }
      }
      return {
        text: "Undefined",
        color: "grey"
      };
    }
  }
};
</script>

<style lang="scss">
.credit-score-badge {
  @import "@/styles/common.scss";
  @include badge-styles;
  .badge {
    color: white !important;
    font-weight: normal;
  }
  .red {
    background-color: #bd2327 !important;
  }
  .orange {
    background-color: #f89937 !important;
  }
  .yellow {
    background-color: #ecd206 !important;
  }
  .green {
    background-color: #059d4c !important;
  }
}
</style>
